import React, { createContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import {
  useFirebaseAuth,
  useUserData,
  useOrgData,
  useSetUserData,
  useFirebaseAuthToken,
  useProjectData,
} from "../utils/hooks"
import Loader from "../components/Loader"
import {
  COMPLETE_STATE,
  defaultContext,
  Environment,
  SERVICE_ACCOUNTS_ROUTE,
} from "../utils/constants"
import { updateIdClaims } from "../utils/firebase"
import { logger } from "../utils/helpers"

const intialState = {
  user: {},
  userData: {},
  orgData: {},
  projectData: {},
  productionMode: false,
  token: "",
}

export const performProperRedirect = navigate => {
  const pathName = window.location.pathname
  const splitPath = pathName.split("/")

  if (splitPath.length === 4 && splitPath[2] === "serviceaccounts") {
    navigate(SERVICE_ACCOUNTS_ROUTE)
  }
}

export const UserContext = createContext(defaultContext)

const UserProvider: React.FunctionComponent = ({ children }) => {
  const [state, setState] = useState(intialState)
  const [productionMode, setProductionMode] = useState(false)
  const [queryComplete, setQueryComplete] = useState(false)
  const { user, initializing } = useFirebaseAuth()
  const token = useFirebaseAuthToken()
  const { upsertUser } = useSetUserData()
  const { userData } = useUserData()
  const orgId = userData?.organizationID || ""
  const { orgData } = useOrgData(orgId)
  const { projectData } = useProjectData(orgId)
  const projectId = projectData.uid || ""

  const waitingOnData = user && (!orgId || !projectId)

  useEffect(() => {
    setState({
      ...state,
      user,
      userData,
      orgData,
      productionMode,
      token,
      projectData,
    })
  }, [
    initializing,
    user,
    userData,
    orgData,
    orgId,
    projectData,
    productionMode,
    token,
  ])

  async function setupEnvironment(newEnvironment: Environment) {
    try {
      await updateIdClaims(newEnvironment)
      setProductionMode(newEnvironment === Environment.Production)
      performProperRedirect(navigate)
    } catch (e) {
      logger.info(`SETUP-ENV: Faileld to Setup Env | ${JSON.stringify(e)}`)
    }
  }

  useEffect(() => {
    const newEnvironment = productionMode
      ? Environment.Production
      : Environment.Sandbox
    user && setupEnvironment(newEnvironment)
  }, [user])

  const upgradeRequested = () => {
    const updatedUserData = {
      ...userData,
    }
    updatedUserData.productionRequested = true
    setState({
      ...state,
      userData: updatedUserData,
    })
  }

  useEffect(() => {
    const env = productionMode ? Environment.Production : Environment.Sandbox
    const queriesInEnvironment = userData?.queries?.[env]
    if (!queriesInEnvironment) {
      setQueryComplete(false)
      return
    }

    if (Object.keys(queriesInEnvironment).length < 1) {
      setQueryComplete(false)
    } else {
      for (const apiType in queriesInEnvironment) {
        setQueryComplete(queriesInEnvironment[apiType].state === COMPLETE_STATE)
      }
    }
  }, [userData])

  if (initializing || waitingOnData) {
    return <Loader />
  }

  return (
    <UserContext.Provider
      value={{
        user,
        userData,
        upsertUser,
        orgData,
        projectData,
        productionMode,
        upgradeRequested,
        token,
        queryComplete,
        setupEnvironment,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
