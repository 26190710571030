import { setAnalyticsUserProperty } from "./firebase"
import { User } from "./types"
import LogRocket from "logrocket"

export const thirdPartyScriptsEnabled =
  process.env.NODE_ENV === "production" &&
  process.env.GATSBY_THIRD_PARTY_SCRIPT_ENABLED === "true"

export const trackUser = (user: User): void => {
  setAnalyticsUserProperty({
    responsibility: user?.responsibility,
    email_domain: user?.email?.split("@")[1],
    role: user?.role,
  })
  setHubspotUser(user?.email)
}

export const trackOrganization = (organizationName: string): void => {
  setAnalyticsUserProperty({
    organization_name: organizationName,
  })
}

export const getHubspot = (): Array<unknown> => {
  if (!window || typeof window._hsq !== `object`) return []

  const _hsq = window?._hsq ?? []
  return _hsq
}

export const trackHubspotPageview = (path: string): void => {
  const _hsq = getHubspot()
  _hsq.push(["setPath", path])
  _hsq.push(["trackPageView"])
}

export const setHubspotUser = (email: string): void => {
  const _hsq = getHubspot()
  _hsq.push([
    "identify",
    {
      email,
      from_portal: "true",
    },
  ])
}

export const identifyUser = (email: string): void => {
  if (!thirdPartyScriptsEnabled || !LogRocket) return

  LogRocket?.identify(email)
}

export const rollWorksRecordUser = () => {
  try {
    if (window && window.__adroll) {
      window.__adroll.record_user({ adroll_segments: "b93e8daa" })
    }
  } catch (err) {
    console.error(err)
  }
}
