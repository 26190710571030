import React from "react"
import UserProvider from "./src/providers/UserProvider"
import LogRocket from "logrocket"
import "./static/fonts.css"
import "./src/css/styles.css"
import { thirdPartyScriptsEnabled } from "./src/utils/analytics-helpers"

export const wrapRootElement = ({ element }) => (
  <UserProvider>{element}</UserProvider>
)

export const onClientEntry = () => {
  // Redirect to maintenance page if MAINTENANCE_MODE is true
  if (
    process.env.MAINTENANCE_MODE === "true" &&
    window.location.pathname !== "/maintenance"
  ) {
    window.location = "/maintenance"
  }
  if (thirdPartyScriptsEnabled) {
    LogRocket.init("epxaaq/particle-portal")
  }
}
