import { PortalContext, QueryMetricsResponse } from "./types"

export const GOOGLE_PROVIDER = "google_provider"
export const GITHUB_PROVIDER = "github_provider"
export const MICROSOFT_PROVIDER = "microsoft_provider"
export const WELCOME_ROUTE = "/dashboard/welcome"
export const CCDA_VIEWER_ROUTE = "/dashboard/ccda-viewer"
export const LEGAL_ROUTE = "/dashboard/legal"
export const POLICY_ROUTE = "/dashboard/policy"
export const COMPLIANCE_ROUTE = "/dashboard/compliance"
export const EXTERNAL_CCDA_VIEWER_LINK = "https://viewer.particlehealth.com/"
export const PARTNERS_ROUTE = "/dashboard/partner-apis"
export const KEY_MANAGEMENT_ROUTE = "/dashboard/key-management"
export const ORGANIZATION_ROUTE = "/dashboard/organization"
export const USER_SETTINGS = "/dashboard/user-settings"
export const API_CREDENTIALS_ROUTE = "/dashboard/api-credentials"
export const SERVICE_ACCOUNTS_ROUTE = "/dashboard/serviceaccounts"
export const SERVICE_ACCOUNT_ROUTE = "/dashboard/serviceaccounts/:id"
export const DEV_STUDIO_ROUTE = "/dashboard/dev-studio"
export const DOCS_ROUTE = "https://docs.particlehealth.com/docs"
export const QUICKSTART_ROUTE = "https://docs.particlehealth.com/recipes"
export const SUPPORT_SERVICE_DESK_LINK = "mailto:support@particlehealth.com"
export const API_REFERENCE_ROUTE =
  "https://docs.particlehealth.com/reference/getting-started"
export const LOGIN_ROUTE = "/login"
export const MFA_ENROLL_ROUTE = "/mfa-enroll"
export const MFA_VERIFY_ROUTE = "/mfa-verify"
export const SIGNUP_ROUTE = "/signup"
export const PW_RESET_ROUTE = "/reset"
export const ADD_DATA_ROUTE = "/additional-data"
export const ADD_SIGNUP_ROUTE = "/additional-signup"
export const VERIFY_ROUTE = "/verify"
export const CHANGE_PW_ROUTE = "/reset-password"
export const EMAIL_VERIFIED_ROUTE = "/email-verified"
export const EMAIL_CONFIRMATION_ROUTE = "/email-confirmation"
export const SANDBOX_ROUTE = "/sandbox"
export const ACCOUNT_BEING_CREATED_ROUTE = "/account-still-being-created"
export const SIGNUP = "signup"
export const LOGIN = "login"
export const HOME_ROUTE = "/"
export const SECTION_HEADER = "h1-section"
export const NONE = "none"
export const ANCHOR_CLASS = "anchor"
export const JACK_ID = "JACK_ID"
export const COMPLETE_STATE = "COMPLETE"
export const QUERY_NOT_COMPLETE_CODE = "412"
export const NO_FILES_FOUND_CODE = "411"
export const COVID_PATIENT = "COVID_PATIENT"
export const DIABETES_PATIENT = "DIABETES_PATIENT"
export const LUNG_CANCER_PATIENT = "LUNG_CANCER_PATIENT"
export const RANDOM_CONDITION_PATIENT = "RANDOM_CONDITION_PATIENT"
export const RAYMOND_ID = "RAYMOND_ID"
export const TEST_PATIENT_ID = "TEST_PATIENT_ID"
export const DIABETES_DATA_SET = "Diabetes"
export const COVID_DATA_SET = "Covid"
export const LUNG_CANCER_DATA_SET = "Lung Cancer"
export const RANDOM_CONDITION_DATA_SET = "Random Condition"
export const ORIGINAL_DATA_SET = "ORIGINAL_DATA_SET"
export const CCDA_API = "C-CDA API"
export const FHIR_API = "FHIR API"
export const DOCS_API = "Documents API"
export const PATIENTS_API = "Patients API"
export const MEDICATION_REQUEST_RESOURCE = "MedicationRequest"
export const MEDICATION_STATEMENT_RESOURCE = "MedicationStatement"
export const MEDICATION_RESOURCE = "Medication"
export const FHIR_RESPONSE_CACHE_KEY = "fhirResponse"
export const PATIENT_INFO_CACHE_KEY = "patientInfo"
export const CONDITION_RESOURCE = "Condition"
export const RAW_BUNDLE_FILE_NAME = "bundle.json"
export const DIAGNOSTIC_REPORT_RESOURCE = "DiagnosticReport"
export const PRACTITIONER_RESOURCE = "Practitioner"
export const PRACTITIONER_ROLE_RESOURCE = "PractitionerRole"
export const PROCEDURE_RESOURCE = "Procedure"
export const IMMUNIZATION_RESOURCE = "Immunization"
export const ENCOUNTER_RESOURCE = "Encounter"
export const OBSERVATION_RESOURCE = "Observation"
export const ALLERGY_INTOLERANCE_RESOURCE = "AllergyIntolerance"
export const LOCATION_RESOURCE = "Location"
export const OBSERVATION_RESOURCE_SOCIAL_HISTORY =
  "Observation - Social History"
export const OBSERVATION_RESOURCE_LABORATORY = "Observation - Laboratory"
export const OBSERVATION_RESOURCE_VITAL_SIGNS = "Observation - Vital Signs"

export const OBSERVATION_RESOURCE_SOCIAL_HISTORY_CATEGORY = "social-history"
export const OBSERVATION_RESOURCE_LABORATORY_CATEGORY = "laboratory"
export const OBSERVATION_RESOURCE_VITAL_SIGNS_CATEGORY = "vital-signs"
export const NA = "N/A"

export const SET_PASSWORD_PAGE_TITLE = "Particle Health Portal - Set Password"
export const LOGIN_PAGE_TITLE = "Particle Health Portal - Login"

export const DASHBOARD_PAGE_TITLES = {
  [WELCOME_ROUTE]: "Welcome",
  [CCDA_VIEWER_ROUTE]: "C-CDA Viewer",
  [LEGAL_ROUTE]: "Legal",
  [POLICY_ROUTE]: "Policy",
  [COMPLIANCE_ROUTE]: "Compliance",
  [PARTNERS_ROUTE]: "Partners",
  [KEY_MANAGEMENT_ROUTE]: "Key Management",
  [ORGANIZATION_ROUTE]: "Organization",
  [API_CREDENTIALS_ROUTE]: "API Credentials",
  [USER_SETTINGS]: "User Settings",
  [DEV_STUDIO_ROUTE]: "Dev Studio",
  [ACCOUNT_BEING_CREATED_ROUTE]: "Account Still Being Created",
  [SERVICE_ACCOUNTS_ROUTE]: "Service Accounts",
}

// Feature Flags
export const productionAPIDocsEnabled = "productionAPIDocsEnabled"
export const fhirDashboardEnabled = "fhirDashboardEnabled"
export const productionModeEnabled = "productionModeEnabled"
export const multiFactorEnabled = "multiFactorEnabled"
export const serviceAccountsEnabled = "serviceAccountsEnabled"

// Customer Roles
export const NO_ROLE = "None"
export const ORG_ADMIN = "Admin"
export const DEVELOPER_ROLE = "Developer"
export const CLINICIAN_ROLE = "Clinician"

export const ACTIVE_STATUS = "Active"
export const INACTIVE_STATUS = "Inactive"
export const UNKNOWN_STATUS = "Unknown"
export const INVITED_STATUS = "Invited"
export const DEACTIVATED_STATUS = "Deactivated"
export const PENDING_STATUS = "Pending"
export const PRODUCTION_ACCESS_STATUS = "Production Access"

export const CCDA_FEATURE = "CCDA"
export const FHIR_FEATURE = "FHIR"
export const DOCS_FEATURE = "DOCS"
export const PATIENTS_FEATURE = "PATIENTS"

export enum Environment {
  Sandbox = "Sandbox",
  Production = "Production",
}

export const COMMONWELL_DIRECTORY = "Commonwell"
export const CAREQUALITY_DIRECTORY = "Carequality"

export const DOTNET = ".NET"
export const NODEJS = "Node.js"
export const JAVA = "Java"
export const PYTHON = "Python"

export const DOTNET_LANGUAGE = "language-csharp"
export const NODEJS_LANGUAGE = "language-javascript"
export const JAVA_LANGUAGE = "language-java"
export const PYTHON_LANGUAGE = "language-python"

export const AUTHENTICATION = "Authentication"
export const CREATE_PERSON = "Create Person"
export const QUERY_PERSON = "Query Person"
export const QUERY_COMPLETION = "Waiting for Query Completion"
export const EVERYTHING_BUNDLE = "Retrieve Everything"
export const ONLY_MEDICATION = "Retrieve Only Medication"

export const CODE_LANGUAGE_OPTIONS = [PYTHON, JAVA, NODEJS, DOTNET]
export const CODE_LANGUAGE_MAP = {
  [DOTNET]: DOTNET_LANGUAGE,
  [NODEJS]: NODEJS_LANGUAGE,
  [JAVA]: JAVA_LANGUAGE,
  [PYTHON]: PYTHON_LANGUAGE,
}

export const ALL_TIME = "104w"
export const THIRTY_DAYS = "30d"
export const SEVEN_DAYS = "7d"

//style constants
export const LINK_STYLES =
  "font-yellix-medium border-solid border-cerulean border-b-2 hover:border-flame"

export const EMPTY_QUERY_METRICS_RESPONSE: QueryMetricsResponse = {
  labels: [],
  [CCDA_API]: {
    total: {
      count: 0,
      dataset: [],
    },
    success: {
      count: 0,
      dataset: [],
    },
  },
  [FHIR_API]: {
    total: {
      count: 0,
      dataset: [],
    },
    success: {
      count: 0,
      dataset: [],
    },
  },
}

export const IDLE_TIMEOUT_IN_MINS = 10
export const IDLE_TIMEOUT_IN_MS = IDLE_TIMEOUT_IN_MINS * 60 * 1000

export const defaultProject = {
  uid: "",
  createdAt: new Date(),
  oids: {
    Sandbox: "",
  },
  environmentState: {
    Sandbox: "",
  },
  internalIds: {
    Sandbox: "",
  },
  status: "",
  purposeOfUse: [],
}

export const defaultContext: PortalContext = {
  user: {
    uid: "",
    emailVerified: false,
    email: "",
    displayName: "",
    phoneNumber: "",
  },
  userData: {
    displayName: "",
    email: "",
    organizationID: "",
  },
  upsertUser: () => {
    //to make the linter be quiet
  },
  orgData: {},
  projectData: defaultProject,

  environment: Environment.Sandbox,
  productionMode: false,
  upgradeRequested: () => {
    ////to make the linter be quiet
  },
  setupEnvironment: () => {
    ////to make the linter be quiet
  },
  token: "",
  queryComplete: false,
}

export enum AccessLevel {
  User = 1,
  OrganizationAdmin,
  ResellerAdmin,
  ParticleAdmin,
}
export enum AccessLevelName {
  User = "User",
  OrganizationAdmin = "Organization Admin",
  ResellerAdmin = "Reseller Admin",
  ParticleAdmin = "Particle Admin",
}

export const PROJECTS_ORG_VERSION = "3"

// PERMISSIONS
// orgs
export const MANAGE_ORGS = "MANAGE_ORGS"
export const ENABLE_MULTI_PROJECTS = "ENABLE_MULTI_PROJECTS"
//project stuff
export const CREATE_PROJECTS = "CREATE_PROJECTS"
export const EDIT_PROJECTS = "EDIT_PROJECTS"
export const DELETE_PROJECTS = "DELETE_PROJECTS"
export const DEACIVATE_PROJECTS = "DEACTIVATE_PROJECTS"
export const SEARCH_PROJECTS = "SEARCH_PROJECTS"
export const REQUEST_PROD_ACCESS = "REQUEST_PROD_ACCESS"
export const ENABLE_PROD_ACCESS = "ENABLE_PROD_ACCESS"
// service accounts
export const MANAGE_SERVICE_ACCOUNTS = "MANAGE_SERVICE_ACCOUNTS"
// credentials
export const MANAGE_CREDENTIALS = "MANAGE_CREDENTIALS"
// connections
export const MANAGE_CONNECTIONS = "MANAGE_CONNECTIONS"
// users
export const MANAGE_USERS = "MANAGE_USERS"
export const DELETE_USERS = "DELETE_USERS"
export const DEACTIVATE_USERS = "DEACTIVATE_USERS"
export const MOVE_USERS_ORG = "MOVE_USERS_ORG"
// view only
export const VIEW_METRICS = "VIEW_METRICS"
export const VIEW_SETTINGS_PERMISSIONS = "VIEW_SETTINGS_PERMISSIONS"

export const PARTICLE_ADMIN_ACCESS_LEVEL = 4
export const RESELLER_ADMIN_ACCESS_LEVEL = 3
export const ORG_ADMIN_ACCESS_LEVEL = 2
export const USER_ACCESS_LEVEL = 1
export const resellerEnabled = "resellerEnabled"

export const API_STATUS_LINK = "http://status.particlehealth.com/"

export const TOO_WEAK = 0
export const WEAK = 1
export const MEDIUM = 2
export const STRONG = 3

export const PASSWORD_STRENGTH_OPTIONS = [
  {
    id: TOO_WEAK,
    value: "Too weak",
    minDiversity: 0,
    minLength: 0,
    color: "error",
  },
  {
    id: WEAK,
    value: "Weak",
    minDiversity: 1,
    minLength: 6,
    color: "error",
  },
  {
    id: MEDIUM,
    value: "Medium",
    minDiversity: 1,
    minLength: 12,
    color: "flame",
  },
  {
    id: STRONG,
    value: "Strong",
    minDiversity: 3,
    minLength: 12,
    color: "cyprus",
  },
]
