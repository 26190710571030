import React from "react"

type Props = {
  backgroundClassName?: string
  fullScreen?: boolean
}

const Loader: React.FunctionComponent<Props> = ({
  backgroundClassName = "bg-particle-grey-30",
  fullScreen = true,
}) => {
  const sizeClasses = fullScreen ? "w-screen h-screen" : "w-auto h-screen"

  return (
    <div
      role="alert"
      className={`${backgroundClassName} ${sizeClasses} flex flex-col justify-center items-center`}
    >
      <div>
        <img src={"/particle-animation.gif"} className="w-64 h-64" />
        <p className="mt-5 font-yellix-regular text-charcoal-grey text-2xl text-center">
          One moment
        </p>
      </div>
    </div>
  )
}

export default Loader
