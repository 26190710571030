import ls from "localstorage-slim"
import { callCloudFunction } from "./firebase"
import {
  RANDOM_CONDITION_PATIENT,
  LUNG_CANCER_PATIENT,
  DIABETES_PATIENT,
  COVID_PATIENT,
  PATIENT_INFO_CACHE_KEY,
  CONDITION_RESOURCE,
  PROCEDURE_RESOURCE,
  IMMUNIZATION_RESOURCE,
  ENCOUNTER_RESOURCE,
  CCDA_FEATURE,
  FHIR_FEATURE,
  OBSERVATION_RESOURCE,
  ALLERGY_INTOLERANCE_RESOURCE,
  OBSERVATION_RESOURCE_LABORATORY,
  OBSERVATION_RESOURCE_VITAL_SIGNS,
  MEDICATION_STATEMENT_RESOURCE,
  DOCS_FEATURE,
  DOCS_API,
  CCDA_API,
  FHIR_API,
  PATIENTS_FEATURE,
  PATIENTS_API,
  PENDING_STATUS,
  AccessLevelName,
  Environment,
  PASSWORD_STRENGTH_OPTIONS,
  MEDIUM,
} from "./constants"
import {
  CreatePersonRequest,
  DemographicQuery,
  DropdownOption,
  ErrorProps,
  Feature,
  Queries,
} from "./types"
import { allColors } from "./colors"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween" // import plugin
dayjs.extend(isBetween)
dayjs.extend(isBetween)

export const sleep = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
export const getQueryParamValue = (key: string): string | null => {
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search).get(key)
  }
  return null
}

export const copyToClipboard = (content: string): void => {
  const el = document.createElement(`textarea`)
  el.value = content
  el.setAttribute(`readonly`, ``)
  el.style.position = `absolute`
  el.style.left = `-9999px`
  document.body.appendChild(el)
  el.select()
  document.execCommand(`copy`)
  document.body.removeChild(el)
}

export const browserBack = (): void => window.history.back()

export const callLogger = (
  name = "clientLogger",
  data: { type: string; message: string }
): void => {
  if (process.env.NODE_ENV !== "production") {
    console.error(data)
  } else {
    callCloudFunction(name, data)
  }
}

export const logger = {
  error: (message: string): void =>
    callLogger("clientLogger", {
      type: "error",
      message,
    }),
  info: (message: string): void =>
    callLogger("clientLogger", {
      type: "info",
      message,
    }),
}

export const isFeatureDisabled =
  process.env.NODE_ENV === "production" &&
  getQueryParamValue("kode") !== process.env.GATSBY_KODE

export const externalNavigate = (url: string): void => {
  if (window) window.location.href = `https://${url}`
}

/**
 * Does a full page redirect to the given path
 * @param path string - relative path to the page
 */
export const fullPageNavigate = (path: string): void => {
  if (window) window.location.href = path
}

/**
 * Opens url in a new tab
 */
export const externalNavigateNewTab = (url: string): void => {
  if (window) window.open(url)
}

/**
 * Sets the local storage value for the given key (encrypted by default, use false for unencrypted)
 */

export const cache = {
  set: <T>(key: string, value: T, encrypt = true): boolean | void => {
    if (encrypt) {
      return ls.set(key, value, { encrypt: true })
    }
    localStorage.setItem(key, JSON.stringify(value))
  },
  get: (key: string): string | unknown => ls.get(key, { decrypt: true }),
  remove: (key: string): boolean | void => ls.remove(key),
}

export const getHostName = (url: string): string => {
  const { host } = new URL(url)
  return host
}

export const getTestPatients = (): Array<{
  displayValue: string
  value: string
}> => [
  {
    displayValue: "Random Condition Patient",
    value: RANDOM_CONDITION_PATIENT,
  },
  { displayValue: "Lung Cancer Patient", value: LUNG_CANCER_PATIENT },
  { displayValue: "Diabetes Patient", value: DIABETES_PATIENT },
  { displayValue: "Covid Patient", value: COVID_PATIENT },
]

export const getFilteredTestPatients = ({
  dataSet,
}: {
  dataSet: string
}): Array<DemographicQuery & { number_of_files: string }> => {
  return allTestPatients[dataSet]
}

export const mapCCDADemoToFHIRCreate = (
  demoRequest: DemographicQuery
): CreatePersonRequest => {
  const finalRequest: CreatePersonRequest = {
    resourceType: "Person",
    name: [
      {
        family: demoRequest.family_name,
        given: [demoRequest.given_name],
      },
    ],
    telecom: [],
    gender: demoRequest.gender,
    birthDate: demoRequest.date_of_birth,
    address: [
      {
        line: [...demoRequest.address_lines],
        city: demoRequest.address_city,
        state: demoRequest.address_state,
        postalCode: demoRequest.postal_code,
      },
    ],
  }

  if (demoRequest.ssn) {
    finalRequest.identifier = [
      {
        type: {
          text: "ssn",
        },
        value: demoRequest.ssn,
      },
    ]
  }

  if (demoRequest.telephone) {
    finalRequest.telecom.push({
      system: "phone",
      value: demoRequest.telephone,
    })
  }

  if (demoRequest.telephone) {
    finalRequest.telecom.push({
      system: "email",
      value: demoRequest.email,
    })
  }
  return finalRequest
}

export const convertTimeToString = (date: string | number): string => {
  if (typeof date !== "string" && typeof date !== "number") return ""
  return dayjs(date).format("MMMM D, YYYY")
}

export const fetchRetry = async (
  url: string,
  options = {},
  retries = 10,
  backoff = 400,
  retryCodes = [202]
): Promise<Response> => {
  try {
    const res = await fetch(url, options)

    if (!retryCodes.includes(res.status) && res.ok) return res

    if (retries > 0 && retryCodes.includes(res.status)) {
      await sleep(backoff)
      return await fetchRetry(url, options, retries - 1, backoff * 2)
    } else {
      const resJson = await res.json()
      throw { message: `${resJson.message} status: ${res.status}` }
    }
  } catch (error) {
    logger.error(
      `FETCH-RETRY-ERROR::failed to retry call. Error ${error.message}`
    )
    throw error
  }
}

export const cachePatientInfo = (patient: DemographicQuery): void => {
  const address = `${patient.address_lines.map(a => a).join(" ")}, ${
    patient.address_city
  }, ${patient.address_state}, ${patient.postal_code}`

  const patientInfo = {
    name: `${patient.given_name} ${patient.family_name}`,
    gender: `${patient.gender}`,
    dob: `${patient.date_of_birth}`,
    ssn: `${patient.ssn}`,
    address,
    telephone: `${patient.telephone}`,
    email: `${patient.email}`,
  }
  cache.set(PATIENT_INFO_CACHE_KEY, patientInfo)
}

// Top level ALL FHIR Resources to download in the Console
export const ALLFHIRResources = [
  MEDICATION_STATEMENT_RESOURCE,
  CONDITION_RESOURCE,
  PROCEDURE_RESOURCE,
  IMMUNIZATION_RESOURCE,
  ENCOUNTER_RESOURCE,
  ALLERGY_INTOLERANCE_RESOURCE,
  OBSERVATION_RESOURCE,
]

// Top level AVAILABLE for viewing FHIR RESOURCES
export const FHIRResources = [
  MEDICATION_STATEMENT_RESOURCE,
  CONDITION_RESOURCE,
  PROCEDURE_RESOURCE,
  IMMUNIZATION_RESOURCE,
  ENCOUNTER_RESOURCE,
  ALLERGY_INTOLERANCE_RESOURCE,
]

// FHIR resources available for selection
export const FHIRResourceSelections = [
  ...FHIRResources,
  OBSERVATION_RESOURCE_LABORATORY,
  OBSERVATION_RESOURCE_VITAL_SIGNS,
]

export const getFHIRResourceConsoleOptions = (): Array<DropdownOption> =>
  FHIRResources.map(resource => ({
    displayValue: resource,
    value: resource,
  }))

export const getFHIRResourceViewerOptions = (): Array<DropdownOption> =>
  FHIRResourceSelections.sort().map(resource => ({
    displayValue: resource,
    value: resource,
  }))

export const get = (
  obj: unknown,
  path: string,
  defValue = null
): string | undefined => {
  // If path is not defined or it has false value
  if (!path) return undefined
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
  // Find value if exist return otherwise return undefined value;
  return (
    pathArray.reduce(
      (prevObj, key) => prevObj && prevObj[key],
      obj as string
    ) || defValue
  )
}

/**
 * Removes all falsey values from array and returns filtered array
 */
export const removeEmpties = <T>(items: Array<T>): Array<T> =>
  items.filter(Boolean)

const DEFAULT_APIS = [CCDA_API, FHIR_API, DOCS_API, PATIENTS_API]
const DEFAULT_SANDBOX_FEATURES = [CCDA_API, FHIR_API]

/**
 * Maps Feature Name to API Name for display
 */
export const mapFeatureToAPIName = (featureName: string): string => {
  switch (featureName) {
    case CCDA_FEATURE:
      return CCDA_API
    case FHIR_FEATURE:
      return FHIR_API
    case DOCS_FEATURE:
      return DOCS_API
    case PATIENTS_FEATURE:
      return PATIENTS_API
    default:
      return ""
  }
}

/**
 * Returns dropdown values for default Sandbox API Options
 */
export const getDefaultAPIOptions = (): Array<DropdownOption> => {
  return [
    { displayValue: "Select API", value: "", props: { disabled: true } },
    ...DEFAULT_APIS.map(apiName => ({
      displayValue: apiName,
      value: apiName,
    })),
  ]
}

/**
 * Retrieves dropdown values for API Playground Options based on Organization Access
 */
export const getAPIPlaygroundOptions = (
  productionMode = false,
  features?: Array<Feature>
): Array<DropdownOption> => {
  if (productionMode) {
    return getAPIOptions(productionMode, features)
  }

  return getDefaultAPIOptions()
}

/**
 * Returns minimum dropdown values for default Sandbox API Options for the API Console
 */
export const getDefaultSandboxFeatureOptions = (): Array<DropdownOption> => {
  return [
    { displayValue: "Select API", value: "", props: { disabled: true } },
    ...DEFAULT_SANDBOX_FEATURES.map(apiName => ({
      displayValue: apiName,
      value: apiName,
    })),
  ]
}

/**
 * Retrieves dropdown values for API Options based on Organization Access
 */
export const getAPIOptions = (
  productionMode = false,
  features?: Array<Feature>
): Array<DropdownOption> => {
  const allowedAPIs: Array<DropdownOption> = [
    { displayValue: "Select API", value: "", props: { disabled: true } },
  ]
  const environment = productionMode
    ? Environment.Production
    : Environment.Sandbox
  // If no features are passed in, return default options
  if (!features || features?.length < 1) {
    if (productionMode) {
      return allowedAPIs
    }
    return getDefaultSandboxFeatureOptions()
  }

  features.forEach(feature => {
    if (feature.isActive && feature.environment === environment) {
      const name = mapFeatureToAPIName(feature.name)
      allowedAPIs.push({
        displayValue: name,
        value: name,
      })
    }
  })
  return allowedAPIs
}

export const getElapsedDays = (date: string): string => {
  if (date === "Unknown") return date
  const today = new Date()
  const dateDifferences = today.getTime() - new Date(date).getTime()
  const numDays = Math.floor(dateDifferences / (1000 * 3600 * 24))
  return numDays > 1 ? `${numDays} Days Ago` : "Today"
}

export const roleSelections = [
  AccessLevelName.User,
  AccessLevelName.OrganizationAdmin,
]

export const getRoleOptions = (): Array<DropdownOption> => [
  { displayValue: "Select Role", value: "", props: { disabled: true } },
  ...roleSelections.map(role => ({
    displayValue: role,
    value: role,
  })),
]

export const getShortDate = (date = new Date()): string => {
  return new Date(date).toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
}

/**
 * Generates a list of uniformly random-ish colors of the given length
 */
export const generateColorList = (numberOfColors: number): Array<string> => {
  const currentColors = Object.keys(allColors)
  let currentColorIndx = 0
  const colorList = []

  for (let i = 0; i < numberOfColors; i++) {
    const currentColor = currentColors[currentColorIndx]
    const currentColorsList = allColors[currentColor]
    const randomIndx = Math.floor(Math.random() * currentColorsList.length)
    colorList.push(currentColorsList[randomIndx])
    if (currentColorIndx === currentColorsList.length - 1) {
      currentColorIndx = 0
    } else {
      currentColorIndx++
    }
  }
  return colorList
}

/**
 * Sorts Object by key
 */
export const sortByKey = <T extends Record<string, unknown>>(
  unorderedData: T
): Record<string, unknown> => {
  return Object.keys(unorderedData)
    .sort()
    .reduce((obj, key) => {
      obj[key] = unorderedData[key]
      return obj
    }, {})
}

/**
 * Scrolls to a section on the page with the given id
 */
export const scrollToSection = (id: string): void =>
  document
    .getElementById(id)
    .scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })

export const range = (
  lastNumber: number,
  startAtOne = false
): Array<number> => {
  const finalNumber = startAtOne ? lastNumber + 1 : lastNumber
  const range = [...Array(finalNumber).keys()]
  if (startAtOne) {
    range.shift()
  }
  return range
}

export const createQueryDocument = async (
  id: string,
  queries: Queries,
  environment: string,
  apiType: string,
  requestData: string,
  upsertUser: ({ queries: Queries }) => void,
  personId = ""
): Promise<void> => {
  const updatedQueries = { ...queries }
  const ttl = 3600 * 1000 * 24 // 24 hours in ms
  const queryInfo = {
    id,
    state: PENDING_STATUS,
    expiry: Date.now() + ttl,
    notificationSent: false,
    requestData,
    personId,
  }

  updatedQueries[environment] = {
    ...updatedQueries[environment],
    [apiType]: queryInfo,
  }

  logger.info(
    `QUERY-STATE-CHECK-START::queryId=${id} environment=${environment} apiType=${apiType}`
  )

  upsertUser({
    queries: updatedQueries,
  })
}

export const isObjectEmpty = (obj: unknown): boolean => {
  return Object.keys(obj).length === 0
}

export const downloadTextFile = (
  text: string,
  fileName = "particle-health-download"
) => {
  const hiddenDownloadButton = document.createElement("a")
  const file = new Blob([text], { type: "text/plain" })
  const downloadUrl = URL.createObjectURL(file)

  hiddenDownloadButton.href = downloadUrl
  hiddenDownloadButton.download = `${fileName}.txt`

  document.body.appendChild(hiddenDownloadButton)
  hiddenDownloadButton.click()
  document.body.removeChild(hiddenDownloadButton)
}
