module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"U3mdx7RQMiB5snYexUWDg7G51VM2jkQd","trackPage":true,"trackPageImmediately":true,"trackPageOnlyIfReady":false,"trackPageOnRouteUpdate":true,"trackPageOnRouteUpdateDelay":50,"trackPageWithTitle":false,"delayLoad":false,"delayLoadDelay":1000,"delayLoadUntilActivity":false,"delayLoadUntilActivityAdditionalDelay":0,"manualLoad":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
