import { useEffect, useState, useRef } from "react"
import { defaultProject, EMPTY_QUERY_METRICS_RESPONSE } from "./constants"
import initFirebase, {
  isAdmin,
  callGetAuth,
  callGetFirestore,
  setAnalyticsUser,
  getToken,
  callCloudFunction,
} from "./firebase"
import { logger } from "./helpers"

import { onAuthStateChanged, onIdTokenChanged, Auth } from "firebase/auth"
import {
  updateDoc,
  doc,
  onSnapshot,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore"
import {
  User as FirestoreUser,
  Organization,
  QueryMetricsResponse,
  AuthUser,
  Project,
  ServiceAccount,
} from "./types"
import { Firestore, getDoc } from "@firebase/firestore"
import { trackOrganization, trackUser } from "./analytics-helpers"

if (typeof window !== "undefined") {
  initFirebase()
}

let firestore: Firestore, auth: Auth

const setGlobals = async () => {
  firestore = await callGetFirestore()
  auth = await callGetAuth()
}

if (typeof window !== "undefined") {
  setGlobals()
}

export const useFirebaseAuth = (): {
  initializing: boolean
  user: AuthUser
} => {
  const [state, setState] = useState(() => {
    const user = auth && auth.currentUser

    return {
      initializing: !user || !user.emailVerified,
      user,
    }
  })

  const onChange = async user => {
    if (user) {
      user.getIdToken(true)
    }
    setState({ initializing: false, user })
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, onChange)
    // Unsubscribe to the listener when unmounting.
    return () => unsubscribe()
  }, [])

  return state
}

/**
 * Watches for token changes (ex: updates to claims)
 * @returns token - String
 */
export const useFirebaseAuthToken = (): string => {
  const [token, setToken] = useState(null)

  const onChange = async (user: AuthUser) => {
    if (user) {
      user.getIdToken(true)
      const { token } = await getToken()
      setToken(token)
    }
  }

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, onChange)
    // Unsubscribe to the listener when unmounting.
    return () => unsubscribe()
  }, [])

  return token
}

export const useQueryMetrics = (
  timeFrame: string,
  productionMode: boolean
): { queryMetrics: QueryMetricsResponse; loading: boolean } => {
  const emptyResponse: QueryMetricsResponse = EMPTY_QUERY_METRICS_RESPONSE
  const [loading, setLoading] = useState(true)
  const [queryMetrics, setQueryMetrics] = useState(emptyResponse)
  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      try {
        const response = await callCloudFunction("getQueryMetrics", {
          timeFrame,
          productionMode,
        })
        const metrics = response.data as QueryMetricsResponse
        setQueryMetrics(metrics)
      } catch (e) {
        logger.error(`API-METRICS-ERROR::${e.message}`)
      }
      setLoading(false)
    }
    getData()
  }, [timeFrame, productionMode])

  return { queryMetrics, loading }
}

export const useSetUserData = (): {
  upsertUser: (data: FirestoreUser) => Promise<void>
} => {
  const user = auth && auth.currentUser
  const uid = user && user.uid
  const upsertUser = async (data: FirestoreUser): Promise<void> => {
    const docRef = doc(firestore, "users", uid)
    return updateDoc(docRef, data)
  }
  return {
    upsertUser,
  }
}

export const useUserData = (): {
  userData: FirestoreUser
} => {
  const [userData, setUserData] = useState(null)
  const user = auth && auth.currentUser
  const uid = user && user.uid

  useEffect(() => {
    if (uid) {
      setAnalyticsUser(uid)
      const docRef = doc(firestore, "users", uid)
      const unsubscribe = onSnapshot(
        docRef,
        async snapshot => {
          const user = snapshot.data()
          trackUser(user as FirestoreUser)
          if (user) {
            user.isAdmin = await isAdmin()
            const { token } = await getToken()
            user.jwt = token
          }
          setUserData(user)
        },
        error =>
          logger.error(
            `USER-DATA-ERROR::error in getting user data ${uid} | ${error.message}`
          )
      )

      return unsubscribe
    }
  }, [uid])

  return {
    userData,
  }
}

export const useOrgData = (orgId: string): { orgData: Organization } => {
  const [orgData, setOrgData] = useState<Organization | null>(null)
  useEffect(() => {
    const getOrgData = async orgId => {
      if (orgId) {
        try {
          const docRef = doc(firestore, "organizations", orgId)
          const snapshot = await getDoc(docRef)
          const orgData = snapshot.data() as Organization
          trackOrganization(orgData?.name as string)
          setOrgData(orgData)
        } catch (error) {
          logger.error(
            `FETCH-ORG-DATA-ERROR::error in getting org data ${orgId} | ${error.message}`
          )
        }
      }
    }
    getOrgData(orgId)
  }, [orgId])

  return {
    orgData,
  }
}

const getMainProjectId = async (orgId: string): Promise<string> => {
  let projectId
  const collectionRef = collection(
    firestore,
    "organizations",
    orgId,
    "projects"
  )

  //this should only ever be 1, hopefully
  const q = query(collectionRef, where("mainProject", "==", true))
  const querySnapshot = await getDocs(q)
  if (querySnapshot.size) {
    querySnapshot.forEach(doc => {
      projectId = doc.id
    })
  }

  return projectId
}

export const useProjectData = (
  orgId: string
): {
  projectData: Project
  loading: boolean
  error: { message: string; code: string }
} => {
  const [projectData, setProjectData] = useState<Project>(defaultProject)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  useEffect(() => {
    const getProjectData = async (orgId: string) => {
      if (orgId) {
        try {
          const projectId = await getMainProjectId(orgId)
          if (projectId) {
            setLoading(true)
            try {
              const docRef = doc(
                firestore,
                "organizations",
                orgId,
                "projects",
                projectId
              )
              const unsubscribe = onSnapshot(
                docRef,
                async snapshot => {
                  const projectSnapshot = snapshot.data() as Project
                  const data = { ...projectSnapshot, uid: projectId }
                  setProjectData(data)
                },
                error => {
                  logger.error(
                    `error in getting projectId: ${projectId} data snapshot ${error.message} code=${error?.code}`
                  )
                  setError(error)
                }
              )
              setLoading(false)
              return unsubscribe
            } catch (error) {
              logger.error(
                `error in getting org data ${error.message} code=${error?.code}`
              )
              setLoading(false)
              setError(error)
            }
          }
        } catch (error) {
          logger.error(
            `error in getting project data orgId: ${orgId} error=${error?.message} code=${error?.code}`
          )
          setLoading(false)
          setError(error)
        }
      }
    }
    getProjectData(orgId)
  }, [orgId])

  return {
    projectData,
    loading,
    error,
  }
}

export const useServiceAccountData = (
  orgId: string,
  environment: string,
  displayName: string
): {
  serviceAccount: ServiceAccount
} => {
  const [serviceAccount, setServiceAccount] = useState({})
  useEffect(() => {
    const getData = async (orgId: string, environment: string) => {
      try {
        const projectId = await getMainProjectId(orgId)
        const collectionRef = collection(
          firestore,
          "organizations",
          orgId,
          "projects",
          projectId,
          "serviceAccounts"
        )

        const userServiceAccountQuery = query(
          collectionRef,
          where("environment", "==", environment),
          where(
            "displayName",
            "==",
            `${displayName}'s ${environment} Credentials`
          )
        )

        const userServiceAccountSnapshot = await getDocs(
          userServiceAccountQuery
        )

        let docRef

        if (userServiceAccountSnapshot.empty) {
          const defaultServiceAccountQuery = query(
            collectionRef,
            where("environment", "==", environment),
            where("displayName", "==", `${environment} Credentials`)
          )

          const defaultSnapshot = await getDocs(defaultServiceAccountQuery)
          docRef = defaultSnapshot?.docs?.[0]?.ref
        } else {
          docRef = userServiceAccountSnapshot?.docs?.[0]?.ref
        }

        const unsubscribe = onSnapshot(docRef, async snapshot => {
          const data = snapshot.data() as ServiceAccount
          const uid = docRef.id
          setServiceAccount({ ...data, uid })
        })

        return unsubscribe
      } catch (error) {
        logger.error(`error in getting service account data ${error.message}`)
      }
    }
    getData(orgId, environment)
  }, [orgId, environment])

  return { serviceAccount }
}

export const useInterval = (callback: () => void, delay: number): void => {
  const savedCallback = useRef(null)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => {
      savedCallback?.current()
    }
    if (delay !== null) {
      const id = window.setInterval(tick, delay)
      return () => window.clearInterval(id)
    }
  }, [delay])
}

export const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: (event: MouseEvent) => void
): void => {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }

    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [ref, handler])
}

export const getOrgUsers = async (uid: string) => {
  const docRef = doc(firestore, "organizations", uid)
  const orgDoc = await getDoc(docRef)
  const userIdsInOrg = orgDoc.data()?.users
  const users = await fetchUserDocs(userIdsInOrg)
  return users
}

export const fetchUserDocs = async (
  uids: Array<string>
): Promise<FirestoreUser[]> => {
  const collectionGets = uids.map(uid => ({
    uid,
    read: getDoc(doc(firestore, "users", uid)),
  }))

  const collectionReads = await Promise.all(
    collectionGets.map(({ uid, read }) =>
      read.then(r => ({ uid, read: r })).catch(() => ({ uid, read: null }))
    )
  )

  const collectionResults = collectionReads.map(v => ({
    uid: v.uid,
    ...(v.read?.data() as FirestoreUser),
  }))

  return collectionResults
}
